import React, { useState } from 'react'

import {
  Collapsible,
  Collapsibles,
  Heading,
  Paragraph
} from '@te-digi/styleguide'

const CollapsiblesExample1 = () => {
  const [isOpen1, setIsOpen1] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)

  return (
    <>
      {/* EXAMPLE_START */}
      <Collapsibles>
        <Collapsible
          bordered
          color="light"
          isOpen={isOpen1}
          label={
            <Heading
              level={1}
              noMargin
              size={4}
            >
              Lorem ipsum dolor sit amet
            </Heading>
          }
          onToggle={() => setIsOpen1(isOpen => !isOpen)}
        >
          <Paragraph noMargin>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
            posuere interdum sem.
          </Paragraph>
        </Collapsible>
        <Collapsible
          bordered
          color="light"
          isOpen={isOpen2}
          label={
            <Heading
              level={1}
              noMargin
              size={4}
            >
              Sed posuere interdum sem
            </Heading>
          }
          onToggle={() => setIsOpen2(isOpen => !isOpen)}
        >
          <Paragraph noMargin>
            Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed
            sapien.
          </Paragraph>
        </Collapsible>
        <Collapsible
          bordered
          color="light"
          disabled
          label={
            <Heading
              level={1}
              noMargin
              size={4}
            >
              Suspendisse facilisis nulla in lacinia laoreet
            </Heading>
          }
        >
          <Paragraph noMargin>
            Quisque purus lectus, posuere eget imperdiet nec sodales id arcu.
          </Paragraph>
        </Collapsible>
      </Collapsibles>
      {/* EXAMPLE_END */}
    </>
  )
}

export { CollapsiblesExample1 }
