import React, { useState } from 'react'

import { Collapsible, Paragraph } from '@te-digi/styleguide'

const CollapsibleExample2 = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {/* EXAMPLE_START */}
      <Collapsible
        inline
        isOpen={isOpen}
        label="Lorem ipsum dolor sit amet"
        onToggle={() => setIsOpen(!isOpen)}
      >
        <Paragraph noMargin>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere
          interdum sem.
        </Paragraph>
      </Collapsible>
      {/* EXAMPLE_END */}
    </>
  )
}

export { CollapsibleExample2 }
