import React from 'react'

import {
  Collapsible,
  CollapsibleContent,
  Collapsibles,
  Paragraph
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { CollapsibleAccessoryExample } from '../../examples/react/CollapsibleAccessoryExample'
import { CollapsibleRequiredMissingExample } from '../../examples/react/CollapsibleRequiredMissingExample'
import { CollapsibleBorderExample } from '../../examples/react/CollapsibleBorderExample'
import { CollapsibleIconExample } from '../../examples/react/CollapsibleIconExample'
import { CollapsibleIconSpacerExample } from '../../examples/react/CollapsibleIconSpacerExample'
import { CollapsibleExample1 } from '../../examples/react/CollapsibleExample1'
import { CollapsibleExample2 } from '../../examples/react/CollapsibleExample2'
import { CollapsibleExample3 } from '../../examples/react/CollapsibleExample3'
import { CollapsibleExample4 } from '../../examples/react/CollapsibleExample4'
import { CollapsibleExample5 } from '../../examples/react/CollapsibleExample5'
import { CollapsibleExample6 } from '../../examples/react/CollapsibleExample6'
import { CollapsiblesExample1 } from '../../examples/react/CollapsiblesExample1'
import { CollapsiblesExample2 } from '../../examples/react/CollapsiblesExample2'
import { CollapsibleStepsExample } from '../../examples/react/CollapsibleStepsExample'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { Link } from '../../components/Link'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Collapsible"
    components={[
      { component: Collapsible, restElement: true },
      { component: CollapsibleContent, restElement: true },
      { component: Collapsibles }
    ]}
  >
    <Section>
      <Paragraph>
        <Code>Collapsible</Code> tiivistää monimutkaisen tai paljon tilaa vievän
        sisällön lyhyeksi kuvaukseksi, jonka avulla käyttäjän on helpompi
        hahmottaa kokonaiskuva näkymän sisällöstä ja päättää mitä osia haluaa
        tarkastella lähemmin.
      </Paragraph>
      <Playground example={CollapsibleExample1} />
    </Section>
    <Section title="Reunaviivalla">
      <Playground example={CollapsibleBorderExample} />
    </Section>
    <Section title="Tiivistetty">
      <Playground example={CollapsibleExample2} />
    </Section>
    <Section title="Painikkeella">
      <Playground example={CollapsibleExample3} />
    </Section>
    <Section title="Ilmoitus puuttuvista tiedoista">
      <Paragraph>
        Esimerkistä huomoitavaa: &quot;Pakollisia tietoja puuttuu&quot; on
        semanttisesti tärkeä tieto, joka tulee nostaa esille. Se on tehty
        linkittämällä otsikon <Code>aria-labelledby</Code>-attribuutilla sekä
        otsikon id että &quot;Pakollisia tietoja puuttuu&quot;-tekstin id
        keskenään. Testatessa havaittiin, että VoiceOVer (+ Safari / Chrome) ei
        lue <Code>aria-describedby</Code>lla linkitettyä tietoa.
      </Paragraph>
      <Playground example={CollapsibleRequiredMissingExample} />
    </Section>
    <Section title="Ikonilla">
      <Paragraph>
        Komponentissa on varattu paikka, jossa voi näyttää esim. ikonin tai{' '}
        <Code>
          <Link page="Bullet" />
        </Code>
        -komponentin.
      </Paragraph>
      <Playground example={CollapsibleIconExample} />
      <Playground example={CollapsibleIconSpacerExample} />
    </Section>
    <Section title="Lisäosalla">
      <Paragraph>
        Komponentissa on varattu paikka lisäosalle, jossa voi näyttää esim.{' '}
        <Code>
          <Link page="Checkbox" />
        </Code>
        -komponentin. Lisäosa asettuu pienemmillä näytöillä otsikon ja
        alaotsikon jälkeen, isoimmilla näytöillä oikeaan laitaan
        nuoli-painikkeen vierelle. Käyttäessä lisäosaa <Code>Collapsible</Code>
        :n klikattava alue rajautuu otsikkoon ja nuoli-painikkeeseen.
      </Paragraph>
      <Playground example={CollapsibleAccessoryExample} />
    </Section>
    <Section title="Yläpuolelle aukeava">
      <Paragraph>Ei suositella.</Paragraph>
      <Playground example={CollapsibleExample4} />
    </Section>
    <Section title="Disabloitu">
      <Paragraph>Ei suositella.</Paragraph>
      <Playground example={CollapsibleExample5} />
    </Section>
    <Section title="Näytä lisää">
      <Paragraph>Ei suositella.</Paragraph>
      <Playground example={CollapsibleExample6} />
    </Section>
    <Section title="Collapsibles">
      <Paragraph>
        <Code>Collapsibles</Code> ryhmittää <Code>Collapsible</Code>-komponentit
        semanttisesti sekä visuaalisesti, sisentäen ja erotellen ne viivoilla.
      </Paragraph>
      <Playground example={CollapsiblesExample1} />
      <Playground example={CollapsiblesExample2} />
    </Section>
    <Section title="CollapsibleSteps">
      <Paragraph>Vaiheistettujen ohjeiden esittämiseen.</Paragraph>
      <Playground example={CollapsibleStepsExample} />
    </Section>
  </Content>
)

export default Page
