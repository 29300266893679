import React, { useState } from 'react'

import { Collapsible, Heading, Paragraph } from '@te-digi/styleguide'

const CollapsibleExample1 = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {/* EXAMPLE_START */}
      <Collapsible
        isOpen={isOpen}
        label={
          <Heading
            level={1}
            noMargin
            size={4}
          >
            Lorem ipsum dolor sit amet
          </Heading>
        }
        onToggle={() => setIsOpen(!isOpen)}
        subLabel={<Paragraph noMargin>Consectetuer adipiscing elit</Paragraph>}
      >
        <Paragraph noMargin>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere
          interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis
          facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu lectus
          auctor vitae, consectetuer et venenatis eget velit. Sed augue orci,
          lacinia eu tincidunt et eleifend nec lacus. Donec ultricies nisl ut
          felis, suspendisse potenti. Lorem ipsum ligula ut hendrerit mollis,
          ipsum erat vehicula risus, eu suscipit sem libero nec erat. Aliquam
          erat volutpat. Sed congue augue vitae neque. Nulla consectetuer
          porttitor pede. Fusce purus morbi tortor magna condimentum vel,
          placerat id blandit sit amet tortor.
        </Paragraph>
      </Collapsible>
      {/* EXAMPLE_END */}
    </>
  )
}

export { CollapsibleExample1 }
