import React, { useState } from 'react'

import {
  ArrowDownIcon,
  Box,
  Bullet,
  Button,
  Col,
  Collapsible,
  Collapsibles,
  Heading,
  Paragraph,
  Row,
  Spacer,
  Text
} from '@te-digi/styleguide'

const CollapsibleStepsExample = () => {
  const [open, setOpen] = useState(1)

  return (
    <Box
      padding={{ xs: 'sm', md: 'md' }}
      paddingTop={{ xs: 'md', md: 'lg' }}
      paddingBottom={{ xs: 'xs', md: 'lg' }}
    >
      <Collapsibles variant="divider">
        <Collapsible
          icon={
            <Bullet
              color="primary"
              variant={open === 1 ? undefined : 'outline'}
              bulletContent="1"
              size="xl"
            />
          }
          isOpen={open === 1}
          onToggle={() => setOpen(1)}
          label={
            <Heading
              level={1}
              noMargin
              size={4}
            >
              Mikä on työnhakuprofiili?
            </Heading>
          }
        >
          <Paragraph noMargin>
            Työnhakuprofiilin avulla löydät osaamistasi vastaavia työpaikkoja.
            Sen avulla kerrot itsestäsi työnantajalle kuka olet ja mitä osaat.{' '}
            <Text
              as="span"
              fontWeight="semi-bold"
            >
              Työnhakuprofiilisi on anonyymi
            </Text>
            , ellet itse päätä toisin.
          </Paragraph>
          <Button
            iconRight={<ArrowDownIcon />}
            onClick={() => setOpen(2)}
            variant="plain"
          >
            Lue seuraava
          </Button>
        </Collapsible>
        <Collapsible
          icon={
            <Bullet
              color="primary"
              bulletContent="2"
              variant={open === 2 ? undefined : 'outline'}
              size="xl"
            />
          }
          isOpen={open === 2}
          onToggle={() => setOpen(2)}
          label={
            <Heading
              level={1}
              noMargin
              size={4}
            >
              Miksi työnhakuprofiili kannattaa täyttää?
            </Heading>
          }
        >
          <Row>
            <Col
              md={{
                order: 2,
                size: 6
              }}
              xs={{
                order: 1,
                size: 12
              }}
            >
              <Box>1.</Box>
            </Col>
            <Col
              md={{
                order: 1,
                size: 6
              }}
              xs={{
                order: 2,
                size: 12
              }}
            >
              <Box>2.</Box>
            </Col>
          </Row>
          <Button
            iconRight={<ArrowDownIcon />}
            onClick={() => setOpen(3)}
            variant="plain"
          >
            Lue seuraava
          </Button>
        </Collapsible>
        <Collapsible
          icon={
            <Bullet
              color="primary"
              bulletContent="3"
              variant={open === 3 ? undefined : 'outline'}
              size="xl"
            />
          }
          isOpen={open === 3}
          onToggle={() => setOpen(3)}
          label={
            <Heading
              level={1}
              noMargin
              size={4}
            >
              Miten työnhakuprofiili toimii? Entä jos otsikko on aivan liian
              pitkä?
            </Heading>
          }
        >
          <Paragraph noMargin>
            Quisque purus lectus, posuere eget imperdiet nec sodales id arcu.
          </Paragraph>
        </Collapsible>
      </Collapsibles>

      <Spacer paddingTop="lg">
        <Button>Ilmoittaudu työnhakijaksi</Button>
      </Spacer>
    </Box>
  )
}

export { CollapsibleStepsExample }
