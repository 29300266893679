import React from 'react'

import { Collapsible } from '@te-digi/styleguide'

const CollapsibleExample5 = () => (
  <>
    {/* EXAMPLE_START */}
    <Collapsible
      disabled
      inline
      label="Lorem ipsum dolor sit amet"
    />
    {/* EXAMPLE_END */}
  </>
)

export { CollapsibleExample5 }
