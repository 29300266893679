import React, { useState } from 'react'

import { Button, Collapsible, Inline, Paragraph } from '@te-digi/styleguide'

const CollapsibleExample6 = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {/* EXAMPLE_START */}
      <Collapsible
        initialHeight={90}
        isOpen={isOpen}
        onToggle={() => setIsOpen(!isOpen)}
        reversed
        trigger={({ getTriggerProps }) => (
          <Inline justifyContent="end">
            <Button
              variant="plain"
              {...getTriggerProps()}
            >
              {isOpen ? 'Näytä vähemmän' : 'Näytä lisää'}
            </Button>
          </Inline>
        )}
      >
        <Paragraph noMargin>
          Fusce purus morbi tortor magna condimentum vel, placerat id blandit
          sit amet tortor. Mauris varius diam vitae arcu. Lorem ipsum ligula ut
          hendrerit mollis, ipsum erat vehicula risus, eu suscipit sem libero
          nec erat. Sed posuere interdum sem. Nulla consectetuer porttitor pede.
          Sed augue orci, lacinia eu tincidunt et eleifend nec lacus. Sed congue
          augue vitae neque. Quisque ligula eros ullamcorper quis, lacinia quis
          facilisis sed sapien. Fusce purus morbi tortor magna condimentum vel,
          placerat id blandit sit amet tortor. Mauris varius diam vitae arcu.
        </Paragraph>
      </Collapsible>
      {/* EXAMPLE_END */}
    </>
  )
}

export { CollapsibleExample6 }
