import React, { useState } from 'react'

import {
  BookIcon,
  Bullet,
  Collapsible,
  Heading,
  Paragraph,
  Spacer
} from '@te-digi/styleguide'

const CollapsibleIconSpacerExample = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {/* EXAMPLE_START */}
      <Collapsible
        icon={
          <Bullet
            color="primary"
            icon={<BookIcon />}
            variant="outline"
            size="xxxl"
          />
        }
        accessory={<Paragraph noMargin>5/2015 - 7/2017</Paragraph>}
        isOpen={isOpen}
        label={
          <Heading
            level={1}
            noMargin
            size={4}
          >
            Kokin perustutkinto
          </Heading>
        }
        onToggle={() => setIsOpen(!isOpen)}
        subLabel={
          <>
            <Paragraph noMargin>Tampereen seudun ammattiopisto</Paragraph>
            <Paragraph noMargin>Opintoni ovat päättyneet</Paragraph>
          </>
        }
      >
        <Spacer
          paddingLeft={{
            lg: 'layout-lg'
          }}
        >
          <Paragraph noMargin>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
            posuere interdum sem. Quisque ligula eros ullamcorper quis, lacinia
            quis facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu
            lectus auctor vitae, consectetuer et venenatis eget velit.
          </Paragraph>
        </Spacer>
      </Collapsible>
      {/* EXAMPLE_END */}
    </>
  )
}

export { CollapsibleIconSpacerExample }
