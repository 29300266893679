import React, { useState } from 'react'

import {
  AlertCircleIcon,
  Collapsible,
  Heading,
  Inline,
  Paragraph,
  Text
} from '@te-digi/styleguide'

const CollapsibleRequiredMissingExample = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Collapsible
      color="light"
      bordered
      isOpen={isOpen}
      label={
        <Heading
          level={1}
          noMargin
          size={4}
          id="heading_text"
          aria-labelledby="heading_text required_info_missing"
        >
          Lorem ipsum dolor sit amet
        </Heading>
      }
      onToggle={() => setIsOpen(!isOpen)}
      subLabel={
        <>
          <Paragraph noMargin>Sublabel text</Paragraph>
          <Inline
            alignItems="center"
            gap="xs"
            id="required_info_missing"
          >
            <AlertCircleIcon
              block
              size="lg"
              color="danger"
            />
            <Text color="danger">Pakollisia tietoja puuttuu</Text>
          </Inline>
        </>
      }
    >
      <Paragraph noMargin>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere
        interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis
        facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu lectus
        auctor vitae, consectetuer et venenatis eget velit. Sed augue orci,
        lacinia eu tincidunt et eleifend nec lacus. Donec ultricies nisl ut
        felis, suspendisse potenti. Lorem ipsum ligula ut hendrerit mollis,
        ipsum erat vehicula risus, eu suscipit sem libero nec erat. Aliquam erat
        volutpat. Sed congue augue vitae neque. Nulla consectetuer porttitor
        pede. Fusce purus morbi tortor magna condimentum vel, placerat id
        blandit sit amet tortor.
      </Paragraph>
    </Collapsible>
  )
}

export { CollapsibleRequiredMissingExample }
